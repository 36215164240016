<template>
  <div>
    <!-- Start Containerbar -->
    <div id="containerbar">
      <!-- Start Rightbar -->
      <div class="rightbar">
        <div class="breadcrumbbar">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
              <h4 class="page-title">Solicitudes</h4>
              <div class="breadcrumb-list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/dashboard">{{ $t("shipment_request.home") }}</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a @click="changeAction(1)">Solicitudes</a>
                  </li>
                  <li class="breadcrumb-item">{{ $t(title) }}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div class="contentbar">
          <div class="row">
            <div class="col-lg-12 col-xl-12 pb-0">
              <div class="card">
                <AlertMessageComponent ref="alertMessageComponent" />
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="card-title">
                        <i class="feather icon-clipboard" aria-hidden="true"></i>
                        {{ $t(title) }}
                      </h5>
                    </div>
                  </div>
                </div>
                <TableRequestComponent
                  v-show="showAction(1)"
                  :headers="headers"
                  :dataList="dataList"
                  :isUpdate="false"
                  :isDetail="withPermissionName(permissions.PRE_REQUEST_AUTHORIZER_EDIT_STATUS_01)"
                  :userRequestAuthorization="true"
                  :detailRequest="detailRequest"
                  :assingUserRequest="assingUserRequest"
                  :isPDF="withPermissionName(permissions.PRE_REQUEST_AUTHORIZER_DL_PDF_02)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalRequestComponent
      :dialog="dialog"
      :modelDetailRequest="modelDetailRequest"
      :addProductCnis="false"
      :addProductDistribution="false"
      :saveRequest="true"
      :userRequestAuthorization="true"
      :persistent="persistent"
      :alert="alert"
      :changeAction="changeAction"
    />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { mapState, mapMutations } from "vuex";
import { PRERESQUEST_VALIDATE } from "@/core/DataTableHeadersLocale";
import { SwalConfirm, SwalInfo } from "@/core/SwalAlert";
import TableRequestComponent from "@/components/shipments-request/TableRequestComponent";
import RequestFormComponent from "@/components/shipments-request/request/RequestFormComponent";
import RequestAddProductsComponent from "@/components/shipments-request/request/RequestAddProductsComponent";
import ModalRequestComponent from "@/components/shipments-request/ModalRequestComponent";
import ButtonAction from "@/common/button/ButtonAction";
import { PreRequest } from "@/core/request";
import moment from "moment";
export default {
  data() {
    return {
      action: 1,
      originIdBranchoffice: "",
      headers: PRERESQUEST_VALIDATE(),
      modelRequest: {
        idShipmentRequest: "",
        refereceRequest: "",
        typeRequest: "",
        destination: "",
        comments: "",
      },
      dialog: {
        dialog: false,
      },
      modelDetailRequest: {},
      sending: false,
      /* Permisos */
      permissions: PermissionConstants,
      loading: false,
      dataList: [],
      productListDistributionTable: [],
      productListCnisSelect: [],
      /* List products for add */
      productList: {
        productListDistribution: [],
        productListCnis: [],
      },
      persistent: {
        persistent: false,
      },
    };
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    title() {
      return this.action == 1
        ? "Solicitudes por autorizar"
        : this.action == 2
        ? "shipment_request.add_shipment_request"
        : "shipment_request.update_shipment_request";
    },
    buttonTitle() {
      return this.action == 2
        ? "Guardar Solicitud"
        : this.action == 3
        ? "Modificar Solicitud"
        : this.action == 4
        ? "Cambio de remisión"
        : this.action == 5
        ? "Modificar información"
        : "";
    },
  },
  methods: {
    // Funcion para el cambio de sucursal global en el state
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    showAction(action) {
      return this.action == action;
    },
    async changeAction(action, type) {
      action == 1 && type ? await this.getInfoGeneral() : null;
      action == 2 ? this.clear() : null;
      this.action = action;
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    async getPrerequest() {
      await PreRequest.getAllRequest({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
        typeUser: 3,
      })
        .then((response) => {
          let { data, message, success } = response.data.data;
          if (success) {
            this.dataList = data;
          } else {
            this.dataList = [];
            /* this.alert("error", message); */
          }
        })
        .catch(() => {
          console.log(error);
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    async getProductBoardDistribution() {
      await PreRequest.getProductBoardDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originIdBranchoffice,
      })
        .then((response) => {
          let { data } = response.data.data;
          this.productListDistributionTable = data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {});
    },
    async verifyRequest(item) {
      let validation = 0;
      validation = await PreRequest.getStatusRequestAssigned({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
        },
        idShipmentRequest: item.idShipmentRequest,
        typeUser: 3,
      })
        .then((response) => {
          let { data, message, success } = response.data.data;
          if (data.status == null) {
            return (validation = 1);
          }
          if (success) {
            if (moment().valueOf() < data.dateExpired) {
              if (UtilFront.getDataUser().idUser == data.id.idUser) {
                return (validation = 3);
              } else {
                return (validation = 2);
              }
            } else if (moment().valueOf() > data.dateExpired) {
              return (validation = 1);
            }
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
      return validation;
    },
    async assingUserRequest(item) {
      const validation = await this.verifyRequest(item);
      switch (validation) {
        case 1:
          const { isConfirmed } = await SwalConfirm.fire({
            title: "Asignación de solicitud",
            html:
              "<div> La siguiente solicitud se te será asignada y tendras un periodo de <b>24hr</b> " +
              "<b>" +
              UtilFront.getNameStatus(item.statusRequest) +
              "</b></div>",
          });
          if (!isConfirmed) {
            return;
          }
          PreRequest.assingUserRequest({
            userBranchOffice: {
              idUser: UtilFront.getDataUser().idUser,
              idBranchOffice: this.originIdBranchoffice,
            },
            idShipmentRequest: item.idShipmentRequest,
            typeUser: 3,
            statusRequestUser: 1,
          })
            .then((response) => {
              let { success, message } = response.data.data;
              if (success) {
                this.detailRequest(item);
              } else {
                this.alert("error", message);
              }
            })
            .catch((error) => {
              console.log(error);
              this.alert("internal");
            })
            .finally(() => {
              this.loading.hide();
            });
          break;
        case 2:
          SwalInfo.fire({
            title: "Estado de la solicitud",
            html:
              "<div> La siguiente solicitud <b>(" +
              item.idShipmentRequest +
              ") ya se encuentra </b> " +
              "<b>" +
              UtilFront.getNameProccess(item.statusRequest) +
              "</b></div>",
          });
          break;
        case 3:
          this.detailRequest(item);
          break;
        default:
          break;
      }
    },
    detailRequest(item) {
      this.loading = UtilFront.getLoadding();
      PreRequest.detailRequest({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
        idShipmentRequest: item.idShipmentRequest,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          if (success) {
            this.modelDetailRequest = data;
            this.dialog.dialog = true;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    updateRequest(item) {
      this.loading = UtilFront.getLoadding();
      PreRequest.detailRequest({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
        idShipmentRequest: item.idShipmentRequest,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          console.log(data);
          if (success) {
            this.modelRequest.idShipmentRequest = data.idShipmentRequest;
            this.modelRequest.refereceRequest = data.requestKey;
            this.modelRequest.typeRequest = data.typeRequest;
            this.modelRequest.supplierRequest = data.destination.idBranchOffice;
            this.modelRequest.comments =
              data.shipmentRequestComments[0].comment;
            this.changeAction(3);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    clear() {
      this.getProductBoardDistribution();
      this.modelRequest.idShipmentRequest = this.modelRequest.comments = this.modelRequest.refereceRequest = this.modelRequest.destination = this.modelRequest.typeRequest =
        "";
    },
    async getInfoGeneral() {
      try {
        this.loading = UtilFront.getLoadding();
        await this.getProductBoardDistribution();
        await this.getPrerequest();
        this.loading.hide();
      } catch (error) {
        this.error(error);
      } finally {
        this.loading.hide();
      }
    },
  },

  async created() {
    this.originIdBranchoffice = this.branchOfficeState.idBranchoffice;
    this.updateAction(1);
    this.action = 1;
    await this.getInfoGeneral();
  },
  watch: {
    async branchOfficeState() {
      this.originIdBranchoffice = this.branchOfficeState.idBranchoffice;
      await this.getInfoGeneral();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.headers = PRERESQUEST();
    },
  },
  components: {
    AlertMessageComponent,
    RequestFormComponent,
    TableRequestComponent,
    ButtonAction,
    ModalRequestComponent,
    RequestAddProductsComponent,
  },
};
</script>
